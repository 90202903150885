import React, { useState, useEffect, useContext } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Image,
  Button,
  Flex,
  Text,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import {
  BrandTitle,
  FooterLogo,
  LoadingSpinner,
  PageHead,
  ScreenContainer,
} from "src/components";
import { UserContext } from "src/context/UserContext";
import { browserName, isIE, isSamsungBrowser } from "react-device-detect";

// Sanity
import { groq } from "next-sanity";
import client from "lib/client";

// Sanity fetch query
const query = groq`
*[_type =='landingPage'
 && _lang == $lang || _type == 'portalSettings' && _lang == $lang]{
    titlePartOne,
    titlePartTwo,
    pageTitle,
    loginButton,
    signupButton,
    availableLanguages,
    aboutLinkText,
    "image": mainImage.asset->url,
    "caption": mainImage.captionImage,
    "logo": uploadLogo.asset->url,
    "logoCaption": uploadLogo.caption
}
`;

export default function Home() {
  const [data, setData] = useState([]);
  const { language, storeLanguage, languages } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    client
      .fetch(query, { lang: language })
      .then((res) => setData(res))
      .catch(console.error);
  }, [language, query]);

  // removing null properties
  data.forEach((obj) => {
    Object.keys(obj).forEach((k) => obj[k] == null && delete obj[k]);
  });

  // merge objects from sanity, destructure values
  const {
    aboutLinkText,
    availableLanguages,
    caption,
    image,
    loginButton,
    logo,
    logoCaption,
    pageTitle,
    signupButton,
    titlePartOne,
    titlePartTwo,
  } = Object.assign({}, ...data);

  const navigate = (path) => {
    if (path) {
      // first parameter: pathname, second: as/alias, third: language
      router.push(`/auth/${path}`, `/auth/${path}`, { locale: language });
    }
  };

  if (!data.length) {
    return <LoadingSpinner />;
  }

  return (
    <ScreenContainer>
      <PageHead title={`${titlePartOne} ${titlePartTwo} :: ${pageTitle}`} />
      <BrandTitle titlePartOne={titlePartOne} titlePartTwo={titlePartTwo} />
      <Image src={image} alt={caption ? caption : "Real winner image"} />
      {browserName == "Facebook" ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>
              The page should be opened in another browser{" "}
            </AlertTitle>
            <AlertDescription display="block">
              You have opened this in the Facebook browser. To register your
              progression when completing the course, please open the page in
              another browser.
              <br />
              On iOS, click… in the top or bottom right corner and select
              &quot;Open in Browser&quot;.
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      {browserName == "Instagram" ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>
              The page should be opened in another browser{" "}
            </AlertTitle>
            <AlertDescription display="block">
              You have opened this in the Instagram browser. To register your
              progression when completing the course, please open the page in
              another browser.
              <br />
              On iOS, click… in the top or bottom right corner and select
              &quot;Open in Browser&quot;.
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      {isSamsungBrowser ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>
              The page should be opened in another browser{" "}
            </AlertTitle>
            <AlertDescription display="block">
              For the best user experience, we recommend using an updated
              browser. We recommend Google Chrome on Android.
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      {isIE ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>
              The page should be opened in another browser{" "}
            </AlertTitle>
            <AlertDescription display="block">
              For the best user experience, we recommend using an updated
              browser. We recommend Edge, Chrome or Firefox on Windows.
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      <Flex pt={6} justify="space-between" align="center">
        <Button mr={2} variant="standard" onClick={() => navigate("login")}>
          {loginButton}
        </Button>
        <Button ml={2} variant="standard" onClick={() => navigate("signup")}>
          {signupButton}
        </Button>
      </Flex>
      <HStack
        mt={6}
        spacing="4px"
        align="center"
        justify="center"
        textTransform="uppercase"
      >
        {availableLanguages && (
          <>
            {availableLanguages?.map((avl, index) => (
              <Button
                key={index}
                bg="transparent"
                pt="3px"
                px="5px"
                fontWeight="normal"
                fontSize="0.96875rem"
                textTransform="uppercase"
                color="linkHover"
                _hover={{ bg: "transparent", textDecoration: "underline" }}
                _active={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                onClick={() => storeLanguage(avl.languageCode)}
              >
                {avl.chosenLanguage}
              </Button>
            ))}
            <Text>|</Text>
          </>
        )}

        {aboutLinkText && (
          <Link
            href={`/home/about${language === "en" ? "" : `-${language}`}`}
            locale={language}
          >
            {aboutLinkText}
          </Link>
        )}
      </HStack>
      <FooterLogo logo={logo} caption={logoCaption} />
    </ScreenContainer>
  );
}
